import React from 'react'

import styled from 'styled-components'
import { Container, Link, A } from '../styled'
import { StaticQuery, graphql } from 'gatsby'

const FooterContainer = styled(Container)`
  padding-top: 2em;
  padding-bottom: 2em;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Footer = props => (
  <FooterContainer>
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              author
            }
          }
        }
      `}
      render={data => (
        <>
          <h5>{data.site.siteMetadata.author} © 2019</h5>
          <h5>
            <A href="https://twitter.com/jonathanvoelkle" target="_blank">
              twitter
            </A>{' '}
            &bull;{' '}
            <A href="https://github.com/jonathanvoelkle/" target="_blank">
              github
            </A>{' '}
            &bull;{' '}
            <A
              href="https://www.instagram.com/jonathanvoelkle/"
              target="_blank"
            >
              instagram
            </A>
          </h5>
        </>
      )}
    />
  </FooterContainer>
)

export default Footer
