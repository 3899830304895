import React from 'react'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'

import Header from './Header'
import Footer from './Footer'

import { H1, H3, Link, A, Container } from '../styled'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }
  body {
    font-family: ${props => props.theme.bodyFontFamily};
    font-weight: 300;
    font-size: 21px;
    line-height: ${props => props.theme.lineHeight};
  }
  p {
    padding: ${props => props.theme.textPadding};
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.headerFontFamily};
    line-height: ${props => props.theme.lineHeight};
    padding: ${props => props.theme.textPadding};
  }
  ul, ol {
    padding-left: ${props => props.theme.listPadding}; 
  }
`

const theme = {
  black: '#000000cc',
  color: '#ffffff',
  grey: '#cccccc',
  inverted: {
    black: '#121212',
    white: '#ffffff',
  },
  bodyFontFamily: ['et-book', 'sans'].join(','),
  headerFontFamily: ['Inter', 'sans-serif'].join(','),
  lineHeight: '1.8em',
  textPadding: '.4em 0em',
  listPadding: '1.2em',
}

function Layout(props) {
  const { location, title, children } = props
  const rootPath = `${__PATH_PREFIX__}/`
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Header {...props}>{title}</Header>
        <Container>{children}</Container>
        <Footer {...props}>hi</Footer>
      </>
    </ThemeProvider>
  )
}

export default Layout
