import React from 'react'
import { Link as GLink } from 'gatsby'
import styled from 'styled-components'

export const H1 = styled.h1`
  margin-bottom: 1em;
  text-decoration: none;
`

export const Link = styled(GLink)`
  text-decoration: none;
  box-shadow: none;
  color: inherit;
`

export const A = styled.a`
  text-decoration: none;
  box-shadow: none;
  color: inherit;
`

export const PostMeta = styled.h5`
  font-weight: 300;
  color: ${props => props.theme.grey};
  letter-spacing: 0.1em;
`

export const Container = styled.div`
  max-width: 1080px;
  margin: auto;
  padding: 0em 5em;
`
