import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'

import { A, Container, Link } from '../styled'

const HeaderContainer = styled(Container)`
  margin-top: 2em;
  h1 {
    margin-top: 3em;
  }
`

const Header = props => {
  console.log(props)
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <HeaderContainer>
          <h3>
            <Link style={{ boxShadow: `none` }} to={''}>
              {data.site.siteMetadata.title}
            </Link>
          </h3>
          <h1>{props.children}</h1>
        </HeaderContainer>
      )}
    />
  )
}

export default Header
