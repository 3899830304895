import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const cow = `



 _____________
(  feeling    )
(  lonely?    )
(  sorry...   )
 -------------
       O
	O   ^__^
	 o  (oo)\\_______
	    (__)\\       )\\/\\
		||----w |
                ||     ||
    


    
`

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Not Found" />
        <h1>Not Found</h1>
        <p>You just hit a route that doesn't exist... the sadness.</p>
        <p>
          There is nothing here. But maybe <Link to="/">here</Link>.
        </p>
        <pre>
          <big>{cow}</big>
        </pre>
      </Layout>
    )
  }
}

export default NotFoundPage
